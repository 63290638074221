<template>
    <div class="block-section">
        <Toast />
        <div class="block-content">
            <div class="surface-ground min-h-screen px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center">
                <div class="surface-card py-3 p-4 shadow-2 border-round w-full md:w-6 lg:w-4">
                    <div class="text-center mb-4">
                        <img src="../assets/logo.png" alt="Image" height="50" class="mb-3">
                        <div class="text-900 text-3xl font-medium mb-3">Welcome to Nishcinto!</div>
                        <span class="text-600 font-medium line-height-3">Sing In to Nishcinto Admin</span>
                    </div>

                    <form @submit.prevent="signin(!valid$.$invalid)" class="p-fluid">
                        <div class="field">
                            <span class="p-fluid">
                                <div class="my-3">
                                    <label for="email1" class="block text-900 font-medium mb-2">Email</label>
                                    <InputText
                                        :class="{'p-invalid': valid$.email.$invalid && submitted}"
                                        id="email1"
                                        type="text"
                                        v-model="valid$.email.$model"
                                    />
                                    <span v-if="valid$.email.$error && submitted">
                                        <span
                                            id="email-error"
                                            v-for="(error, index) of valid$.email.$errors"
                                            :key="index"
                                        >
                                            <small class="p-error">{{error.$message.replace("Value", "E-mail")}}</small>
                                        </span>
                                    </span>
                                    <small
                                        v-else-if="(valid$.email.$invalid && submitted) || valid$.email.$pending.$response"
                                        class="p-error"
                                    >
                                        {{ valid$.email.required.$message.replace("Value", "E-mail") }}
                                    </small>
                                </div>
                            </span>

                            <span class="p-fluid">
                                <div class="my-3">
                                    <label for="password" class="block text-900 font-medium mb-2">Password</label>
                                    <Password
                                        :class="{'p-invalid': valid$.password.$invalid && submitted}"
                                        id="password"
                                        toggleMask
                                        :feedback="false"
                                        v-model="valid$.password.$model"
                                    />
                                    <span v-if="valid$.password.$error && submitted">
                                        <span
                                            id="password-error"
                                            v-for="(error, index) of valid$.password.$errors"
                                            :key="index"
                                        >
                                            <small class="p-error">{{error.$message.replace("Value", "Password")}}</small>
                                        </span>
                                    </span>
                                    <small
                                        v-else-if="(valid$.password.$invalid && submitted) || valid$.password.$pending.$response"
                                        class="p-error"
                                    >
                                        {{ valid$.password.required.$message.replace("Value", "Password") }}
                                    </small>
                                </div>
                            </span>
                        </div>
                        <Button label="Signin" icon="pi pi-user" class="w-full mb-2" :loading="loading" type="submit"></Button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Axios from 'axios';
import { ref, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";
import useVuelidate from '@vuelidate/core';
import { required, minLength, email } from '@vuelidate/validators';

export default {
    setup() {
        const store = useStore();
        const router = useRouter();
        const toast = useToast();

        const state = reactive({
            email : "",
            password : "",
        });

        const rules = {
            email : { required, email },
            password : { required , minLength : minLength(6)},
        };

        const valid$ = useVuelidate (rules, state);

        const submitted = ref(false);
        const loading = ref(false);
        const massage = ref(null);

        const signin = (isFormValid) => {
            loading.value = true;
            submitted.value = true;
            if (!isFormValid) {
                loading.value = false;
                return;
            }
            Axios
                .post("api/auth/admin/signin", {
                    email: state.email,
                    password: state.password,
                })
                .then((res) => {
                    loading.value = false;
                    if (res.data.response == "success") {
                        var currentUser = {
                            id: res.data.data.id,
                            role: res.data.data.role,
                            name: res.data.data.name,
                            email: res.data.data.email,
                            agency_id: res.data.data.agency_id,
                            photo: res.data.data.photo,
                            address: res.data.data.address,
                            dob: res.data.data.dob,
                            nid: res.data.data.nid,
                            mobile: res.data.data.mobile ? formatMobileNumber(res.data.data.mobile) : '',
                            token: res.data.data.token,
                        };
                        store.dispatch(
                            "setUserData",
                            JSON.stringify(currentUser)
                        );
                        router.replace('/admin');
                    } else {
                        massage.value = res.data.message;
                        toast.add({severity:'error', summary: 'Error Message', detail: res.data.message, life: 5000});
                    }
                })
                .catch((err) => {
                    loading.value = false;
                    console.log(err);
                    toast.add({severity:'error', summary: 'Error Message', detail: err, life: 5000});
                });
        };

        const formatMobileNumber = (number) => {
            return '0' + number.toString().slice(-10);
        };

        return {
            valid$,
            submitted,
            loading,
            signin,
            email,
            massage,
            router
        }
    },


}
</script>

<style scoped>

</style>